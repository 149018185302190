import { onError } from "apollo-link-error";
import { makeToast } from "@/shared/utils/toast";
import { i18nTranslate } from "@/plugins/i18n";

export const errorHandlerLink = onError(
  ({ graphQLErrors, networkError, operation }) => {
    /**
     * Add console log, this will be available on logrocket
     */
    console.group("errorHandlerLink");
    console.log(`graphQLErrors`, !!graphQLErrors);
    console.log(`networkError`, !!networkError);
    console.log(`errorResponse`, { graphQLErrors, networkError, operation });
    console.groupEnd();

    if (graphQLErrors) {
      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("Something went wrong. Please try again later.")
      );
    } else if (networkError) {
      /**
       * If error thrown is this error again
       *
       * Do not proceed if error is "Failed to fetch"
       * - this means that the user do not have internet and logging will also fail
       */
      if (
        (networkError.message && networkError.message === "NETWORK_ERROR") ||
        (networkError.message && networkError.message === "Failed to fetch") ||
        operation.operationName === "createLog"
      ) {
        return;
      }

      makeToast(
        "error",
        i18nTranslate("Error"),
        i18nTranslate("A network error occurred, Kindly refresh and try again.")
      );
    }
  }
);
