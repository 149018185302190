import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-05277a0e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-layout__menu-ada"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_a_menu_item = _resolveComponent("a-menu-item")!
  const _component_a_menu = _resolveComponent("a-menu")!

  return (_openBlock(), _createBlock(_component_a_menu, {
    mode: _ctx.mode,
    theme: _ctx.theme,
    selectedKeys: _ctx.selectedKeys,
    "onUpdate:selectedKeys": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedKeys) = $event)),
    onClick: _ctx.onClick,
    disabledOverflow: true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (menuItem) => {
        return (_openBlock(), _createBlock(_component_a_menu_item, {
          key: menuItem.routeName
        }, {
          default: _withCtx(() => [
            _createVNode(_component_router_link, {
              to: `/${menuItem.routeName}`
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", null, _toDisplayString(menuItem.label) + _toDisplayString(" "), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            (menuItem.subLabel)
              ? (_openBlock(), _createElementBlock("label", _hoisted_1, [
                  _createElementVNode("b", null, _toDisplayString(_ctx.merchantBrand), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["mode", "theme", "selectedKeys", "onClick"]))
}