
import { computed, defineComponent, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import { useFetch } from "vue-composable";
import AmountText from "@/shared/components/typography/AmountText.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const fetchUsdRate = useFetch();

    const fetchUsdRateLoading = computed(
      () => fetchUsdRate.loading && !fetchUsdRate?.json?.value?.data
    );

    const fetchUsdRateTimerId = window.setInterval(
      () =>
        fetchUsdRate.exec("https://api.coinbase.com/v2/prices/ADA-USD/spot"),
      5000
    );

    onUnmounted(() => {
      if (fetchUsdRateTimerId) {
        window.clearInterval(fetchUsdRateTimerId);
      }
    });

    return {
      usdRateData: fetchUsdRate.json,
      t,
      fetchUsdRateLoading: fetchUsdRateLoading,
    };
  },
  props: {},
  components: {
    AmountText,
  },
});
