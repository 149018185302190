import { dateFormats } from "@/shared/utils/date";
import { defaultTo, toNumber } from "lodash";

const recaptchaSiteKey = process.env.VUE_APP_RECAPTCHA_SITE_KEY || "";
const logRocketId = process.env.VUE_APP_LOG_ROCKET_ID || "";

export const config = {
  allowAutomatedTestMode:
    process.env.VUE_APP_ALLOW_AUTOMATED_TEST_MODE === "true",
  httpGqlServerUrl: process.env.VUE_APP_HTTP_GQL_SERVER_URL || "",
  websocketGqlServerUrl: process.env.VUE_APP_WEBSOCKET_GQL_SERVER_URL || "",
  merchantBrand: process.env.VUE_APP_MERCHANT_BRAND || "",
  contactEmail: process.env.VUE_APP_MERCHANT_BRAND_CONTACT_EMAIL || "",

  /**
   * LOCALIZATION AND NUMBER FORMATS
   * for number format convert to number, .env returns string
   */
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  minimumFractionDigits: toNumber(
    defaultTo(process.env.VUE_APP_NUMBER_FORMAT_MINIMUM_FRACTION, 0)
  ),
  maximumFractionDigits: toNumber(
    process.env.VUE_APP_NUMBER_FORMAT_MAXIMUM_FRACTION || 2
  ),

  /**
   * reCAPTCHA
   */
  recaptchaSiteKey,
  isCaptchaEnabled: !!recaptchaSiteKey,

  /**
   * Logrocket
   *
   * have to check against string "true" because .env uses string type
   * only allow logrocket on development/test environment
   */
  logRocketId,
  logRocketEnabled:
    process.env.VUE_APP_LOG_ROCKET_ENABLE === "true" && !!logRocketId,

  /** static links */
  contactUrl: process.env.VUE_APP_CONTACT_URL,
  privacyPolicyUrl: process.env.VUE_APP_PRIVACY_POLICY_URL,
  cookiePolicyUrl: process.env.VUE_APP_COOKIE_POLICY_URL,
  termsWebsiteUseUrl: process.env.VUE_APP_TERMS_WEBSITE_USE_URL,
};

// TODO: Update once we need to export individual widgets/component
export const isFullSite = true;
