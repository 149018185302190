import sharedRouteNames from "@/shared/router/routeNames";

export default {
  blockList: "blocks",
  epochs: "epochs",
  epochDetails: "epochDetails",
  transactions: "transactions",
  pools: "pools",
  tokens: "tokens",
  accounts: "accounts",
  favorites: "favorites",
  poolDetails: "poolDetails",
  transactionDetails: "transactionDetails",
  accountDetails: "accountDetails",
  blockDetails: "blockDetails",
  ...sharedRouteNames,
};
