
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { menuItems } from "@/shared/composables/useMenuRoutes";
import HeaderLayout from "@/shared/components/layouts/HeaderLayout.vue";
import FooterLayout from "@/shared/components/layouts/FooterLayout.vue";
import SiderLayout from "@/shared/components/layouts/SiderLayout.vue";
import { useRoute } from "vue-router";
import ResponsiveContainer from "@/shared/components/layouts/ResponsiveContainer.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const route = useRoute();
    const isRouteNoPadding = ref(false);
    const isCollapsed = ref(true);
    const verticalMenuFlagRef = ref(true);

    // Assign value when route names change
    watch(
      () => route.name,
      async (newName) => {
        setTimeout(() => (isCollapsed.value = true), 300);

        // Setting to true if the current route is dashboard
        isRouteNoPadding.value = await [newName].includes("home");
      }
    );

    return {
      t,
      isCollapsed,
      verticalMenuFlagRef,
      menuItems,
      onBreakpoint: (broken) => {
        verticalMenuFlagRef.value = !broken;
      },
      isRouteNoPadding,
    };
  },
  components: {
    HeaderLayout,
    FooterLayout,
    SiderLayout,
    ResponsiveContainer,
  },
});
