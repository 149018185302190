import { ApolloClient, InMemoryCache, ApolloLink } from "@apollo/client/core";
import { provideApolloClient } from "@vue/apollo-composable";
import { httpLink } from "@/api/graphqlClient/httpLink";
import { errorHandlerLink } from "@/api/graphqlClient/errorHandlerLink";

/// See documentation of library @see https://v4.apollo.vuejs.org/guide-composable/

export const makeApolloClientInstance = () => {
  const cache = new InMemoryCache();

  return new ApolloClient({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    link: ApolloLink.from([errorHandlerLink, httpLink]),
    cache,
    defaultOptions: {
      query: {
        notifyOnNetworkStatusChange: true,
        fetchPolicy: "no-cache",
      },
    },
  });
};

export const apolloProvider = provideApolloClient(makeApolloClientInstance());
