import { logRocketInit } from "./shared/utils/logrocket";
import { isFullSite } from "@/shared/utils/config";
logRocketInit();

/**
 * Use this to import configured site app
 *
 * This structure will allow us to build the site in the
 * different modules in the case we need it. For example we need to
 * only build the epoch table wihout including all the other
 * pages on the final build
 *
 */
let app;
if (isFullSite) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  app = require("@/web/entryApp").default;
}

export default app;
