
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import FooterColumn1 from "@/shared/components/layouts/FooterColumn1.vue";
import { config } from "@/shared/utils/config";
import moment from "moment";
import { dateFormats } from "@/shared/utils/date";
import ResponsiveContainer from "@/shared/components/layouts/ResponsiveContainer.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const currentTimeStamp = Date.now();

    return {
      t,
      merchantBrand: config.merchantBrand,
      currentYear: moment(currentTimeStamp).format(dateFormats.yearFormat),
    };
  },
  components: { ResponsiveContainer, FooterColumn1 },
});
