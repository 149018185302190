import { createI18n, LocaleMessages, VueMessageType } from "vue-i18n";
import { config, isFullSite } from "@/shared/utils/config";
import isEmpty from "lodash/isEmpty";

/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */
function loadLocaleMessages(): LocaleMessages<VueMessageType> {
  // Webpack require.context only works with string literals
  let locales: __WebpackModuleApi.RequireContext;

  // TODO: Update this accordingly if we need to export individual widgets
  // eslint-disable-next-line prefer-const
  locales = require.context(
    "../locales",
    true,
    /web.[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages: LocaleMessages<VueMessageType> = {};
  locales.keys().forEach((key) => {
    const matched = key.match(/\.([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

/**
 * Default number formats
 *
 * For other format options, check https://vue-i18n.intlify.dev/guide/essentials/number.html
 */
export const numberFormats = {
  en: {
    decimal: {
      style: "decimal",
      minimumFractionDigits: config.minimumFractionDigits,
      maximumFractionDigits: config.maximumFractionDigits,
    },
  },
  cs: {
    decimal: {
      style: "decimal",
      minimumFractionDigits: config.minimumFractionDigits,
      maximumFractionDigits: config.maximumFractionDigits,
    },
  },
};

const i18nMain = createI18n({
  legacy: false,
  locale: config.locale,
  fallbackLocale: config.fallbackLocale,
  messages: loadLocaleMessages(),
  numberFormats,
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  missingWarn: false,
});

export const i18nTranslate = (key: string, options = {}): string => {
  if (!key) {
    return "";
  }

  const result = i18nMain.global.t(key, options);

  if (isEmpty(result)) {
    return key;
  }

  return result;
};

export const i18nNumberFormat = i18nMain.global.n;

export default i18nMain;
