
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import ResponsiveContainer from "@/shared/components/layouts/ResponsiveContainer.vue";
import RemainingEpochTime from "@/shared/components/feedback/RemainingEpochTime.vue";
import CurrentExchangeRate from "@/shared/components/feedback/CurrentExchangeRate.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  props: {},
  components: {
    RemainingEpochTime,
    CurrentExchangeRate,
    ResponsiveContainer,
  },
});
