import { reactive } from "vue";
import cloneDeep from "lodash/cloneDeep";

/// More info on https://austincooper.dev/2019/08/09/vue-observable-state-store
/// Only use this for simple stores. For complicated stores that will be used by
/// more than 4 components use Vuex.
/// When using this make sure to manually clear the store on component destroy.
/// i.e.
///    onUnmounted(() => {
///      simpleStore.resetStore();
///    });
export const makeSimpleStore = <T>(defaultStructure: T) => {
  const store = reactive<T & { resetStore: () => void }>(
    cloneDeep({
      ...defaultStructure,
      resetStore() {
        const newStoreValues = cloneDeep(defaultStructure);
        Object.assign(store, newStoreValues);
      },
    })
  );

  return store;
};
