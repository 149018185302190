import { RouteRecordRaw } from "vue-router";

/* eslint-disable */
// @ts-ignore
import devToolsRoutes from "./devToolsRoutes";
/* eslint-enable */

/**
 * Note: Add `shared` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. sharedHome)
 */
const sharedRoutes: Array<RouteRecordRaw> = [
  ...devToolsRoutes,
  {
    path: "/:catchAll(.*)",
    component: () =>
      import(/* webpackChunkName: "sharedNotFound" */ "../views/NotFound.vue"),
  },
];

export default sharedRoutes;
