import routeNames from "@/web/router/routeNames";
import { i18nTranslate } from "@/plugins/i18n";
import { makeSimpleStore } from "@/shared/utils/simpleStoreUtils";

export type MenuItemType = {
  label: string;
  subLabel: boolean;
  routeName: string;
};

/**
 * Make a menu shared state since there are 2 components (Sidebar and Header Navbar)
 * that use the selectedKeys function.
 * Each update should be reflected for both components
 */
export const menuSimpleStore = makeSimpleStore<{ selectedKeys: Array<string> }>(
  {
    selectedKeys: [],
  }
);

// ======== MENU ======== //
/** TODO: check if menu item needs to have ADA+ beside its name;
 * change subLabel boolean depending on above condition
 */
export const menuItems = [
  {
    label: i18nTranslate("Epochs"),
    subLabel: false,
    routeName: routeNames.epochs,
  },
  {
    label: i18nTranslate("Blocks"),
    subLabel: false,
    routeName: routeNames.blockList,
  },
  {
    label: i18nTranslate("Transactions"),
    subLabel: false,
    routeName: routeNames.transactions,
  },
  {
    label: i18nTranslate("Pools"),
    subLabel: false,
    routeName: routeNames.pools,
  },
  // {
  //   label: i18nTranslate("Tokens"),
  //   subLabel: false,
  //   routeName: routeNames.tokens,
  // },
  {
    label: i18nTranslate("Accounts"),
    subLabel: false,
    routeName: routeNames.accounts,
  },
  // {
  //   label: i18nTranslate("Favorites"),
  //   subLabel: false,
  //   routeName: routeNames.favorites,
  // },
];
