import { makeToast } from "@/shared/utils/toast";
import isEmpty from "lodash/isEmpty";
import isPlainObject from "lodash/isPlainObject";

export type GqlError = {
  isValid: boolean;
  message: string | null;
  errors: {
    message: string;
    code?: string;
    __typename: string;
  }[];
};

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * @deprecated
 */
export const handleGqlError = (
  response,
  successFunction: () => void
): { isValid: boolean } => {
  const result = { isValid: true };

  if (isPlainObject(response?.data)) {
    for (const responseItem in response?.data) {
      const responseItemValue = response?.data[responseItem];

      if (!isEmpty(responseItemValue?.errors)) {
        /// TODO Add logic here to customize message based on error code if needed
        /// TODO Apply localization once it is supported outside of setup function
        makeToast("error", "Error", responseItemValue?.errors[0].message);
        result.isValid = false;
      }
    }
  }

  if (successFunction && result.isValid) {
    successFunction();
  }

  return result;
};

export enum HideIfErrorCode {
  ALL_ERRORS = "*",
}

/**
 * Use this to parse error from GQL response. Errors are not handled
 * on apollo link since we are using a custom union error responses
 *
 * All custom error message based on code should be handled here
 *
 * To be used in conjunction with [parseGqlResponse]
 */
export const parseGqlError = (response, hideIfErrorCode?): GqlError => {
  const result = { isValid: true, message: null, errors: [] };

  if (!isEmpty(response?.errors)) {
    /// TODO Add logic here to customize message based on error code if needed
    /// TODO Apply localization once it is supported outside of setup function
    if (
      hideIfErrorCode !== HideIfErrorCode.ALL_ERRORS &&
      (hideIfErrorCode == null || response?.errors[0].code != hideIfErrorCode)
    ) {
      makeToast("error", "Error", response?.errors[0].message);
    }
    result.isValid = false;
    result.message = response?.errors[0].message;
    result.errors = response?.errors;
  }

  return result;
};
