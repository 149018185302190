
import { defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";
import { MenuItemType } from "@/shared/composables/useMenuRoutes";
import MenuLayout from "@/shared/components/layouts/MenuLayout.vue";
import ResponsiveContainer from "@/shared/components/layouts/ResponsiveContainer.vue";
import CurrentEpochInfoBar from "@/shared/components/feedback/CurrentEpochInfoBar.vue";

export default defineComponent({
  setup() {
    const t = useI18n().t;

    return {
      t,
    };
  },
  props: {
    menuItems: {
      type: Object as PropType<MenuItemType[]>,
      required: true,
    },
  },
  components: {
    ResponsiveContainer,
    MenuLayout,
    CurrentEpochInfoBar,
  },
});
