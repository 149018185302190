
import { defineComponent, PropType, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { config } from "@/shared/utils/config";
import {
  MenuItemType,
  menuSimpleStore,
} from "@/shared/composables/useMenuRoutes";
import { first, isEmpty } from "lodash";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const selectedKeys = ref(menuSimpleStore.selectedKeys);
    const router = useRouter();

    watchEffect(() => {
      const path = router.currentRoute.value.path;
      /** updates store value with selectedKeys value
       * if selectedKeys Ref value does not match with it;
       * so all components that use the store's selectedKeys can
       * have the same value*/
      if (selectedKeys.value != menuSimpleStore.selectedKeys) {
        selectedKeys.value = menuSimpleStore.selectedKeys;
      } else if (
        /** updates both selectedKeys values (store and ref) in case
         * ref value does not match with the current page route
         * (e.g. instance when a page is recently refreshed)
         */
        isEmpty(selectedKeys.value) ||
        first(selectedKeys.value) === "/" ||
        !selectedKeys.value.includes(path)
      ) {
        menuSimpleStore.selectedKeys = [`${path.split("/")[1]}`];
        selectedKeys.value = menuSimpleStore.selectedKeys;
      }
    });

    return {
      t,
      selectedKeys,
      merchantBrand: config.merchantBrand,
      onClick: (item) => {
        menuSimpleStore.selectedKeys = [item.key];
      },
    };
  },
  props: {
    menuItems: {
      type: Object as PropType<MenuItemType[]>,
      required: true,
    },
    theme: String,
    mode: String,
  },
  components: {},
});
