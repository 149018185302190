import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "amount-text-typography-component" }
const _hoisted_2 = {
  key: 0,
  class: "ml-4"
}
const _hoisted_3 = {
  key: 1,
  class: "ml-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i18n_n = _resolveComponent("i18n-n")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", null, _toDisplayString(`${_ctx.isNegative ? "−" : ""} ${_ctx.prefixText}`), 1),
    _createElementVNode("span", {
      class: _normalizeClass(_ctx.amountTextClass)
    }, [
      _createVNode(_component_i18n_n, {
        value: _ctx.amountNum,
        format: "decimal",
        locale: _ctx.getPreferredLanguage()
      }, {
        integer: _withCtx((slotProps) => [
          _createElementVNode("span", null, _toDisplayString(slotProps?.integer), 1)
        ]),
        fraction: _withCtx((slotProps) => [
          _createElementVNode("span", {
            class: _normalizeClass([
              (_ctx.minPrecision > 2 || _ctx.maxPrecision > 2) && 'amount-text__fraction',
            ])
          }, _toDisplayString(slotProps?.fraction), 3)
        ]),
        _: 1
      }, 8, ["value", "locale"])
    ], 2),
    (!_ctx.useSymbol && _ctx.currencyCode)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.currencyCodeDisplay), 1))
      : _createCommentVNode("", true),
    (!_ctx.currencyCode && _ctx.unit)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.unit), 1))
      : _createCommentVNode("", true)
  ]))
}