import { createApp, h } from "vue";
import router from "./router";
import App from "./WebApp.vue";
import { apolloProvider } from "@/api/graphqlClient/apolloClient";

/**
 * Setup web app
 */

export default createApp({
  setup() {
    apolloProvider;
  },
  render: () => h(App),
  config: {
    productionTip: false,
    silent: true,
  },
}).use(router);
