import sharedRoutePaths from "@/shared/router/routePaths";

export default {
  blockList: "/blocks",
  epochs: "/epochs",
  epochDetails: "/epochs/:hash",
  transactions: "/transactions",
  pools: "/pools",
  tokens: "/tokens",
  accounts: "/accounts",
  favorites: "/favorites",
  poolDetails: "/pools/:hash",
  transactionDetails: "/transactions/:hash",
  accountDetails: "/accounts/:hash",
  blockDetails: "/blocks/:hash",
  ...sharedRoutePaths,
};
