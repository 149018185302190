import moment from "moment";

export const dateFormats = {
  default: "MM/DD/YYYY HH:mm:ss A",
  defaultDateWithNoTime: "MM/DD/YYYY",
  dateTime: "MM/DD/YYYY, hh:mm:ss A",
  time: "hh:mm:ss A",
  yearFormat: "YYYY",
};

export const formatDate = (timestamp) => {
  return moment
    .utc(timestamp)
    .local()
    .format(dateFormats.defaultDateWithNoTime);
};

export const formatTime = (timestamp) => {
  return moment.utc(timestamp).local().format(dateFormats.time);
};

export const formatDateTime = (timestamp) => {
  return moment.utc(timestamp).local().format(dateFormats.dateTime);
};
