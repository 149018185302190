import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4b2d91c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "remaining-epoch-time__remaining-time" }
const _hoisted_2 = {
  key: 0,
  class: "mr-4"
}
const _hoisted_3 = {
  key: 1,
  class: "mr-4"
}
const _hoisted_4 = {
  key: 2,
  class: "mr-4"
}
const _hoisted_5 = {
  key: 3,
  class: "remaining-epoch-time__time-element"
}
const _hoisted_6 = { class: "remaining-epoch-time__time-element" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!

  return (_openBlock(), _createBlock(_component_a_skeleton, {
    active: "",
    paragraph: { rows: 0 },
    loading: _ctx.epochStatisticsLoading,
    class: "my-0 remaining-epoch-time__skeleton"
  }, {
    default: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.t("Current Epoch ")) + " " + _toDisplayString(_ctx.parsedGqlEpochStatistics?.data?.epochNo) + " ", 1),
      _createElementVNode("span", _hoisted_1, [
        (_ctx.remainingTime.days)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.remainingTime.days) + _toDisplayString(_ctx.t("d")), 1))
          : _createCommentVNode("", true),
        (_ctx.remainingTime.hours)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.remainingTime.hours) + _toDisplayString(_ctx.t("h")), 1))
          : _createCommentVNode("", true),
        (_ctx.remainingTime.minutes)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.remainingTime.minutes) + _toDisplayString(_ctx.t("m")), 1))
          : _createCommentVNode("", true),
        (_ctx.remainingTime?.seconds != null)
          ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.padStart(_ctx.remainingTime?.seconds, 2, "0")) + _toDisplayString(_ctx.t("s")), 1))
          : _createCommentVNode("", true),
        _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("left")), 1)
      ])
    ]),
    _: 1
  }, 8, ["loading"]))
}