import gql from "graphql-tag";

export const epochStatisticsGql = gql`
  query CurrentEpoch {
    currentEpoch {
      ... on CurrentEpoch {
        blocksCount
        epochNo
        endTimestamp
        startTimestamp
      }

      ... on ResponseErrors {
        errors {
          message
        }
      }
    }
  }
`;
