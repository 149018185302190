
import { useI18n } from "vue-i18n";
import { defineComponent, computed } from "vue";
import upperCase from "lodash/upperCase";
import toNumber from "lodash/toNumber";
import cloneDeep from "lodash/cloneDeep";
import { numberFormats as globalNumberFormats } from "@/plugins/i18n";
import { getPreferredLanguage } from "@/shared/utils/numbers";

export default defineComponent({
  props: {
    amount: {
      type: [Number, String],
      required: true,
    },
    currencyCode: {
      type: String,
    },
    useSymbol: {
      type: Boolean,
      default: false,
    },
    postFixSymbol: {
      type: Boolean,
      default: false,
    },
    unit: {
      type: String,
    },
    minPrecision: {
      type: Number,
      default: 6,
    },
    maxPrecision: {
      type: Number,
      default: 6,
    },
    isNegative: {
      type: Boolean,
      default: false,
    },
    prefixText: {
      type: String,
      default: "",
    },
    amountTextClass: String,
  },
  setup(props) {
    // Update global decimal formats
    const numberFormats = cloneDeep(globalNumberFormats);

    Object.entries(numberFormats).forEach(([key, value]) => {
      numberFormats[key] = {
        decimal: {
          ...value.decimal,
          minimumFractionDigits: props.minPrecision,
          maximumFractionDigits: props.maxPrecision,
        },
      };
    });

    const { t } = useI18n({
      numberFormats,
    });

    const currencyCodeDisplay = computed(() => {
      return upperCase(props.currencyCode);
    });

    const amountNum = computed(() => {
      return toNumber(props.amount);
    });

    return {
      t,
      // computed values
      currencyCodeDisplay,
      amountNum,
      getPreferredLanguage,
    };
  },
});
