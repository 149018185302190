
import { computed, defineComponent, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useQuery } from "@vue/apollo-composable";
import {
  CurrentEpoch,
  CurrentEpoch_currentEpoch_CurrentEpoch,
} from "@/api/epochs/__generated__/CurrentEpoch";
import { epochStatisticsGql } from "@/api/epochs/epochStats";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import moment from "moment";
import padStart from "lodash/padStart";

export default defineComponent({
  setup() {
    const t = useI18n().t;
    const epochStatistics = useQuery<CurrentEpoch>(epochStatisticsGql, {});
    const counterSeconds = ref(0);

    const parsedGqlEpochStatistics = computed(() =>
      parseGqlResponse<CurrentEpoch_currentEpoch_CurrentEpoch>(
        "CurrentEpoch",
        epochStatistics?.result?.value
      )
    );

    const counterTimerId = window.setInterval(
      () => counterSeconds.value++,
      1000
    );

    const remainingTime = computed(() => {
      const diffTime =
        432000 -
        (moment
          .utc(parsedGqlEpochStatistics?.value?.data?.endTimestamp)
          .unix() -
          moment
            .utc(parsedGqlEpochStatistics?.value?.data?.startTimestamp)
            .unix()) -
        counterSeconds.value;
      const duration = moment.duration(diffTime * 1000, "milliseconds");

      return {
        days: duration.days(),
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
      };
    });

    onUnmounted(() => {
      if (counterTimerId) {
        window.clearInterval(counterTimerId);
      }
    });

    return {
      t,
      remainingTime,
      parsedGqlEpochStatistics,
      epochStatisticsLoading: epochStatistics.loading,
    };
  },
  props: {},
  components: {},
  methods: {
    padStart,
  },
});
