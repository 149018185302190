import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import sharedRoutes from "@/shared/router";
import routeNames from "./routeNames";
import routePaths from "./routePaths";
import NProgress from "nprogress";

/**
 * Note: Add `web` prefix to webpackChunkName to prevent overlap with other routes
 * (e.g. webHome)
 */
const webRoutes: Array<RouteRecordRaw> = [
  {
    path: routePaths.home,
    name: routeNames.home,
    redirect: routePaths.epochs,
  },
  {
    path: routePaths.blockList,
    name: routeNames.blockList,
    component: () =>
      import(/* webpackChunkName: "webBlocklist" */ "../views/BlockList.vue"),
  },
  {
    path: routePaths.pools,
    name: routeNames.pools,
    component: () =>
      import(/* webpackChunkName: "webPoolList" */ "../views/PoolList.vue"),
  },
  {
    path: routePaths.accounts,
    name: routeNames.accounts,
    component: () =>
      import(
        /* webpackChunkName: "webAccountList" */ "../views/AccountList.vue"
      ),
  },
  {
    path: routePaths.epochs,
    name: routeNames.epochs,
    component: () =>
      import(/* webpackChunkName: "webEpochList" */ "../views/EpochList.vue"),
  },
  {
    path: routePaths.epochDetails,
    name: routeNames.epochDetails,
    component: () =>
      import(
        /* webpackChunkName: "webEpochDetails" */ "../views/EpochDetails.vue"
      ),
  },
  {
    path: routePaths.transactions,
    name: routeNames.transactions,
    component: () =>
      import(
        /* webpackChunkName: "webTransactionList" */ "../views/TransactionList.vue"
      ),
  },
  {
    path: routePaths.poolDetails,
    name: routeNames.poolDetails,
    component: () =>
      import(
        /* webpackChunkName: "webPoolDetails" */ "../views/PoolDetails.vue"
      ),
  },
  {
    path: routePaths.transactionDetails,
    name: routeNames.transactionDetails,
    component: () =>
      import(
        /* webpackChunkName: "webTransactionDetails" */ "../views/TransactionDetails.vue"
      ),
  },
  {
    path: routePaths.accountDetails,
    name: routeNames.accountDetails,
    component: () =>
      import(
        /* webpackChunkName: "webAccountDetails" */ "../views/AccountDetails.vue"
      ),
  },
  {
    path: routePaths.blockDetails,
    name: routeNames.blockDetails,
    component: () =>
      import(
        /* webpackChunkName: "webBlockDetails" */ "../views/BlockDetails.vue"
      ),
  },
  ...sharedRoutes,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // @see https://stackoverflow.com/a/57212309 this will ensure user will see top most of the
  // screen when navigating to different pages
  routes: webRoutes,
  scrollBehavior() {
    document.getElementById("app")?.scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach((to, from, next) => {
  // Start the route progress bar.
  NProgress.start();
  next();
});

/** The afterEach hook tells the router that
 *  after a link has completely evaluated to
 *  stop the progress bar, it shouldn’t care
 *  if the page request succeeds */
router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
