import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RemainingEpochTime = _resolveComponent("RemainingEpochTime")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_CurrentExchangeRate = _resolveComponent("CurrentExchangeRate")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_ResponsiveContainer = _resolveComponent("ResponsiveContainer")!

  return (_openBlock(), _createBlock(_component_ResponsiveContainer, null, {
    default: _withCtx(() => [
      _createVNode(_component_a_row, {
        justify: "space-between",
        class: "w-100"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_RemainingEpochTime)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { class: "current-epoch-info-bar__exchange-rate" }, {
            default: _withCtx(() => [
              _createVNode(_component_CurrentExchangeRate)
            ]),
            _: 1
          }),
          _createVNode(_component_a_col, { class: "text-right" }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.t("EN")), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}