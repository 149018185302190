import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FooterColumn1 = _resolveComponent("FooterColumn1")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_ResponsiveContainer = _resolveComponent("ResponsiveContainer")!
  const _component_a_layout_footer = _resolveComponent("a-layout-footer")!
  const _component_a_layout = _resolveComponent("a-layout")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "footer-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_a_layout_footer, { class: "footer-layout__details" }, {
        default: _withCtx(() => [
          _createVNode(_component_ResponsiveContainer, null, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, {
                gutter: 16,
                class: "w-100"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_col, {
                    span: 6,
                    xs: 24,
                    sm: 6
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_FooterColumn1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_a_row, { class: "footer-layout__currency-details ant-row-center" }, {
        default: _withCtx(() => [
          _createVNode(_component_ResponsiveContainer, { class: "footer-layout__footer-bar" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_col, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("{merchantBrand} {year} © All rights reserved", {
              merchantBrand: _ctx.merchantBrand,
              year: _ctx.currentYear,
            })), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_col, {
                span: "flex",
                class: "text-right"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.t("EN")), 1)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}