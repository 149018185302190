/* eslint-disable @typescript-eslint/no-empty-function */

//  eslint-disable-next-line @typescript-eslint/no-explicit-any
const _window: any = window;

/**
 * TODO: Move to config.ts if used outside of logger.ts
 */
const isLoggerDisabled =
  process.env.VUE_APP_DISABLE_CONSOLE_LOGS === "true" ||
  process.env.NODE_ENV === "production";

const disallowLoggerEnabling =
  process.env.VUE_APP_DISALLOW_LOGGER_ENABLING === "true";

/**
 * TODO: export if console have to be used regardless of the .env (No use case for now)
 */
const originalConsoleLog = console.log;
const originalConsoleWarn = console.warn;
const originalConsoleError = console.error;
const originalConsoleDir = console.dir;
const originalConsoleGroup = console.group;
const originalConsoleGroupEnd = console.groupEnd;

_window.disableConsoleLog = function () {
  try {
    console.log = function () {};
    console.warn = function () {};
    console.error = function () {};
    console.dir = function () {};
    console.group = function () {};
    console.groupEnd = function () {};
  } catch (error) {
    originalConsoleError(
      "Something went wrong when trying to disable logs",
      error
    );
  }
};

_window.enableConsoleLog = function () {
  if (disallowLoggerEnabling) {
    return;
  }

  try {
    console.log = originalConsoleLog;
    console.warn = originalConsoleWarn;
    console.error = originalConsoleError;
    console.dir = originalConsoleDir;
    console.group = originalConsoleGroup;
    console.groupEnd = originalConsoleGroupEnd;

    /**
     * this means console has been enabled successfully
     */
    console.log("Console enabled!");
  } catch (error) {
    originalConsoleError(
      "Something went wrong when trying to enable logs",
      error
    );
  }
};

if (isLoggerDisabled) {
  _window.disableConsoleLog();
}
