
import {
  MenuItemType,
  menuSimpleStore,
} from "@/shared/composables/useMenuRoutes";
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";
import MenuLayout from "@/shared/components/layouts/MenuLayout.vue";
import { useVModel } from "vue-composable";

export default defineComponent({
  setup(props, { emit }) {
    const t = useI18n().t;
    const selectedKeys = ref(menuSimpleStore.selectedKeys);
    const isCollapsed = useVModel(props, "collapsedModel");

    return {
      t,
      selectedKeys,
      isCollapsed,
      onCollapse: (collapsed) => {
        emit("onCollapse", collapsed);
      },
      onBreakpoint: (broken) => {
        emit("onBreakpoint", broken);
      },
    };
  },
  emits: ["onBreakpoint", "onCollapse"],
  props: {
    showSider: Boolean,
    menuItems: {
      type: Object as PropType<MenuItemType[]>,
      required: true,
    },
    collapsedModel: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    MenuLayout,
  },
});
