import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SiderLayout = _resolveComponent("SiderLayout")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_layout_content = _resolveComponent("a-layout-content")!
  const _component_FooterLayout = _resolveComponent("FooterLayout")!
  const _component_a_layout = _resolveComponent("a-layout")!
  const _component_HeaderLayout = _resolveComponent("HeaderLayout")!
  const _component_ResponsiveContainer = _resolveComponent("ResponsiveContainer")!

  return (_openBlock(), _createBlock(_component_a_layout, { class: "visitor-layout" }, {
    default: _withCtx(() => [
      _createVNode(_component_SiderLayout, {
        onOnBreakpoint: _ctx.onBreakpoint,
        showSider: !_ctx.verticalMenuFlagRef,
        style: { height: '100vh' },
        menuItems: _ctx.menuItems,
        "collapsed-model": _ctx.isCollapsed,
        "onUpdate:collapsed-model": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.isCollapsed) = $event))
      }, null, 8, ["onOnBreakpoint", "showSider", "menuItems", "collapsed-model"]),
      (!_ctx.verticalMenuFlagRef)
        ? (_openBlock(), _createBlock(_component_a_layout, {
            key: 0,
            class: "header-layout"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_row, { class: "ant-row-center mobile-header-layout" }),
              _createVNode(_component_a_layout_content, {
                class: _normalizeClass(['visitor-layout__content', _ctx.isRouteNoPadding && 'no-padding'])
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["class"]),
              _createVNode(_component_FooterLayout)
            ]),
            _: 3
          }))
        : (_openBlock(), _createBlock(_component_a_layout, { key: 1 }, {
            default: _withCtx(() => [
              (_ctx.verticalMenuFlagRef)
                ? (_openBlock(), _createBlock(_component_HeaderLayout, {
                    key: 0,
                    menuItems: _ctx.menuItems
                  }, null, 8, ["menuItems"]))
                : _createCommentVNode("", true),
              _createVNode(_component_a_layout_content, {
                class: _normalizeClass(['visitor-layout__content', _ctx.isRouteNoPadding && 'no-padding'])
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ResponsiveContainer, null, {
                    default: _withCtx(() => [
                      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                    ]),
                    _: 3
                  })
                ]),
                _: 3
              }, 8, ["class"]),
              _createVNode(_component_FooterLayout)
            ]),
            _: 3
          }))
    ]),
    _: 3
  }))
}