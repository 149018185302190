import { resolveComponent as _resolveComponent, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_VisitorLayout = _resolveComponent("VisitorLayout")!

  return (_openBlock(), _createBlock(_component_VisitorLayout, null, {
    default: _withCtx(() => [
      _createVNode(_Transition, {
        name: "scale",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}